<template>
  <div>
    <b-table
      :items="tableItems"
      :fields="tableFields"
      striped
      outlined
      responsive
      stacked="sm"
      small
      show-empty
      :empty-text="emptyText"
      :tbody-tr-class="getRowCssClass"
    >
      <template v-slot:cell(remove)="{ item }">
        <div class="d-flex justify-content-center">
          <b-button
            aria-label="remove"
            variant="danger"
            size="sm"
            :class="[isMobile ? 'mobile-button' : 'round-button']"
            :disabled="!!item.inherited"
            @click="removeMetric(item.metricCode)">
            <font-awesome-icon v-if="!isMobile" icon="times" />
            <p v-if="isMobile">Remove</p>
          </b-button>
        </div>
      </template>

      <template v-slot:cell(name)="{ item }">
        <template v-if="item.invalid">
          <div
            class="d-flex align-items-center"
            v-b-tooltip="{ title: 'This metric does not apply to this module since the metric is based on this module.', boundary: 'window' }">
            <font-awesome-icon icon="exclamation-triangle" />
            <div class="ml-2">{{ item.name }}</div>
          </div>
        </template>

        <template v-else>{{ item.name }}</template>
      </template>
    </b-table>

    <fieldset class="p-2 form-col">
      <legend>Default Metric Assignment</legend>
      <multiselect
        v-model="metricType"
        :options="metricTypeOptions"
        track-by="code"
        label="name"
        selectLabel=""
        deselectLabel=""
        placeholder="Metric Type">
      </multiselect>

      <multiselect
        v-model="metric"
        :options="metricOptions"
        track-by="uuid"
        label="name"
        selectLabel=""
        deselectLabel=""
        placeholder="Metric"
        class="mt-2">
        <template #noOptions>
          {{ metricType ? 'None of your metrics are the same type as the selected metric type' : 'A metric type must first be selected' }}
        </template>
      </multiselect>

      <b-button
        aria-label="add"
        variant="primary"
        size="sm"
        class="mt-2"
        @click="addMetric"
        :disabled="!metricType || !metric">
        Add
      </b-button>
    </fieldset>
  </div>
</template>

<script>
import { BTable, BButton } from 'bootstrap-vue';
import Multiselect from 'vue-multiselect';
import { get } from 'vuex-pathify';

export default {
  name: 'DefaultMetrics',
  components: {
    BTable,
    BButton,
    Multiselect
  },
  props: {
    value: Object,
    inheritedMetrics: Object,
    metrics: Array
  },
  data() {
    return {
      metricType: null,
      metric: null,
      tableFields: [
        { key: 'metricTypeName', label: 'Metric Type' },
        { key: 'name', label: 'Metric' },
        { key: 'inherited', label: 'Inherited From' },
        { key: 'remove', label: '' }
      ],
      emptyText: 'No default metrics are set. Add a new default metric below.'
    };
  },
  computed: {
    metricTypes: get('metricTypes/metricTypes'),
    isMobile() {
      return this.$feature.mobile;
    },
    tableItems() {
      if (!this.metrics.length || !this.metricTypes.length) return [];

      const order = { site: 0, group: 1 };
      const inheritedDefaultMetrics = Object.keys(this.inheritedMetrics || {}).map((key) => {
        const { metricUuid, inherited, invalid } = this.inheritedMetrics[key];
        const metric = this.metrics.find(m => m.uuid === metricUuid);
        const metricType = this.metricTypes.find(t => t.code === metric.metricCode);
        if (!invalid) return { ...metric, metricTypeName: metricType.name, inherited };
        return { ...metric, metricTypeName: metricType.name, inherited };
      }).sort((a, b) => order[a.inherited] - order[b.inherited]);

      const defaultMetrics = Object.keys(this.value || {}).map((key) => {
        const metric = this.metrics.find(m => m.uuid === this.value[key]);
        const metricType = this.metricTypes.find(t => t.code === metric.metricCode);
        return { ...metric, metricTypeName: metricType.name };
      });

      return [...inheritedDefaultMetrics, ...defaultMetrics];
    },
    metricTypeOptions() {
      return this.metricTypes.map((t) => {
        if (this.value[t.code]) return { ...t, $isDisabled: true };
        return t;
      });
    },
    metricOptions() {
      if (!this.metricType) return [];
      return this.metrics
        .filter(m => this.metricType.code === m.metricCode)
        .map(m => ({ ...m, $isDisabled: m.invalid }));
    }
  },
  methods: {
    addMetric() {
      if (this.metricType && this.metric) {
        this.$emit('input', { ...this.value, [this.metricType.code]: this.metric.uuid });
        this.metricType = null;
        this.metric = null;
      }
    },
    removeMetric(code) {
      const defaultMetrics = { ...this.value };
      delete defaultMetrics[code];
      this.$emit('input', defaultMetrics);
    },
    getRowCssClass(item) {
      if (item && item.invalid) return 'invalid-row';
      return '';
    }
  },
  watch: {
    metricType() {
      this.metric = null;
    }
  }
};
</script>

<style scoped lang="scss">
fieldset.form-col {
  border: thin solid rgb(233, 236, 239);
  border-radius: 5px;
}

fieldset.form-col legend {
  font-size: 1em;
  padding-right: 1px;
  padding-left: 1px;
  width: auto;
  margin: 0;
  color: rgb(0, 0, 0);
}

.round-button {
  padding: 0;
  width: 24px;
  height: 24px;
  border-radius: 12px;
}

.mobile-button {
  padding: 0;
  width: 100%;
  height: 24px;
  border-radius: 0.25rem;
}

::v-deep .invalid-row {
  color: #aaa;
  background-color: #fff !important;
}

::v-deep .invalid-row .round-button {
  background-color: #aaa;
  border-color: #aaa;
}

::v-deep .invalid-row .mobile-button {
  background-color: #aaa;
  border-color: #aaa;
}
</style>
